import {Skeleton} from 'antd';
import React, {lazy} from 'react';

import {TodosBlockParams} from './TodosBlock';

export const LazyTodosBlock = lazy(() => import('./TodosBlock'));
export const AsyncLazyTodosBlock: React.FC<TodosBlockParams> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyTodosBlock {...props} />
        </React.Suspense>
    );
};
