import {sizes} from '@sharefiledev/antd-config';
import {Flex, Skeleton, Spin} from 'antd';
import React from 'react';

import {LocalPageProps} from './LocalPage';
import {ProjectTabExtensionProps} from './ProjectTabExtension';

const LazyProjectTabExtension = React.lazy(() => import('./ProjectTabExtension'));
export const AsyncLazyProjectTabExtension: React.FC<ProjectTabExtensionProps> = (props) => (
    <React.Suspense
        fallback={
            <Flex style={{paddingBottom: sizes.XS, paddingRight: sizes.MD}}>
                <Spin />
            </Flex>
        }
    >
        <LazyProjectTabExtension {...props} />
    </React.Suspense>
);

const LazyLocalPage = React.lazy(() => import('./LocalPage'));
export const AsyncLazyLocalPage: React.FC<LocalPageProps> = (props) => (
    <React.Suspense fallback={<Skeleton />}>
        <LazyLocalPage {...props} />
    </React.Suspense>
);
